import React from 'react'
import "./styles.scss"
import { Container } from 'react-bootstrap'
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { BorderBottom } from '@mui/icons-material';
const faqs = [
    {
        question: "Can I pay my account on an annual basis?",
        answer:
            "We follow the monthly and quarterly policy.",
    },
    {
        question: "Are there any unstated costs?",
        answer:
            "All prices are inclusive of taxes.",
    },
    {
        question: "Will my invoice be pro-rated if I sign up in the middle of the month?",
        answer:
            "Yes . Your invoice will be considered for the offers of the month. ",
    },
    {
        question: "What if I have more than 36 staff/locations?",
        answer:
            "Our dedicated staff will serve you where ever you want.",
    },
    {
        question: "What if I need a larger-scale solution than just additional calendars?",
        answer:
            "You need to schedule appointment at least 48 hours before in case of large scale service.  ",
    },
    {
        question: "Can I cancel at anytime?",
        answer:
            "You can cancel anytime. ",
    },
];
const FAQ = () => {
    const theme = useTheme();

    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
    const isTablet = useMediaQuery(theme.breakpoints.down("md"));
    const isLaptop = useMediaQuery(theme.breakpoints.down("lg"));

    const [expanded, setExpanded] = React.useState(false);
    const [panelIndex, setPanelIndex] = React.useState(null);
    const handleChange = (panel) => (event, isExpanded) => {
        console.log(panel)
        console.log(isExpanded)
        setPanelIndex(isExpanded ? panel : null)
        setExpanded(isExpanded ? panel : false);
    };
    return (
        <Container className='faq-container'>
            <div className='PricePlans-title-container'>
                <h1 className='Component-titlee-colors PricePlans-title'>Frequently Asked Questions</h1>
            </div>
            <div style={{ marginTop: "80px" }}>
                {faqs.map((faq, index) => {
                    return (
                        <Accordion
                            expanded={expanded === `panel${index}`}
                            onChange={handleChange(`panel${index}`)}
                            // className="mui-accordian"
                            elevation={0}

                        >
                            <AccordionSummary
                                // expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1bh-content"
                                id="panel1bh-header"
                                className="mui-accordian"
                            >
                                <Typography
                                    sx={{
                                        width: "33%",
                                        flexShrink: 0,
                                        fontSize:
                                            isMobile || isTablet ? "14px" : isLaptop ? "16px" : "20px",
                                    }}
                                    className="faq-title"
                                >
                                    {panelIndex === `panel${index}` ? <KeyboardArrowDownIcon /> : <KeyboardArrowRightIcon />}{faq.question}
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography className="faq-content">{faq.answer}</Typography>
                            </AccordionDetails>
                        </Accordion>
                    );
                })}
            </div>
        </Container>
    )
}

export default FAQ