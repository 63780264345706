import React from "react";
import AdjustIndustry from "../components/AdjustIndustry/AdjustIndustry";
import ClientReview from "../components/ClientReview/ClientReview";
import FAQ from "../components/FAQ/FAQ";
import Footer from "../components/Footer/Footer";
import FourthSection from "../components/FourthSection/FourthSection";
import HomeHeader from "../components/HomeHeader/HomeHeader";
import Introduction from "../components/Introduction/Introduction";
import Navbar from "../components/Navbar/Navbar";
import PaymentMethodBanner from "../components/PaymentMethodBanner/PaymentMethodBanner";
import PowerMeet from "../components/PowerMeet/PowerMeet";
import PricePlans from "../components/PricePlans/PricePlans";
import SecondSection from "../components/SecondSection/SecondSection";
import ThirdSection from "../components/ThirdSection/ThirdSection";
import ToolScheduling from "../components/ToolScheduling/ToolScheduling";

export default function Home() {
  return (
    <>
      <Navbar />
      <HomeHeader />
      {/* <PaymentMethodBanner/> */}
      <PowerMeet></PowerMeet>
      <SecondSection></SecondSection> 



      <ThirdSection></ThirdSection> 
      <FourthSection></FourthSection>  


      <ToolScheduling></ToolScheduling>
      <ClientReview></ClientReview>
      <AdjustIndustry></AdjustIndustry>
      <PricePlans></PricePlans>
      <FAQ/>
      <Footer />  
    </>
  );
}
