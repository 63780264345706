import { Route, Routes, useLocation } from "react-router-dom";
import { useLayoutEffect, lazy, Suspense } from "react";
import "./App.css"
import Home from "./pages/Home";
import TermAndCondition from "./pages/TermAndCondition";
import HelpCenter from "./pages/HelpCenter";
import PricePlans from "./pages/PricePlans";
const Wrapper = ({ children }) => {
  const location = useLocation();
  useLayoutEffect(() => {
    document.documentElement.scrollTo(0, 0);
  }, [location.pathname]);
  return children;
};
function App() {
  return (
    <>
      {/* <Wrapper /> */}
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route exact path="/term-of-service" element={<TermAndCondition />} />
        <Route exact path="/help-center" element={<HelpCenter />} />
        <Route exact path="/price-plan" element={<PricePlans/>} />
      </Routes>
    </>
  );
}

export default App;
