import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import Button from "../Button/Button";
import "./styles.scss";

import logo from "../../assets/images/navbar-logo.png";

function Navbar() {
  const [click, setClick] = useState(false);

  const handleClick = () => setClick(!click);
  return (
    <>
      <nav className="navbar">
        <div className="nav-container">
          <NavLink exact to="/" className="nav-logo">
            <img src={logo} alt="Thee Help" />
          </NavLink>

          <ul className={click ? "nav-menu active" : "nav-menu"}>
            <li className="nav-item">
              <NavLink exact to="/" className="nav-links" onClick={handleClick}>
                Home
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                exact
                to="/price-plan"
                className="nav-links"
                onClick={handleClick}
              >
                Pricing
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                exact
                to="/help-center"
                className="nav-links"
                onClick={handleClick}
              >
                Help
              </NavLink>
            </li>

            <li  className="nav-item" style={{display:"flex" , justifyContent:"center" , marginBottom:"6px"}}>
              <a href="https://thee-help-web-app.vercel.app/" >
              <Button title={"Log In"} width="110px"  />
              </a> 
            </li>
             
          </ul>
          <div className="nav-icon"  onClick={handleClick}>
            <i className={click ? "fas fa-times" : "fas fa-bars"}></i>
          </div>
        </div>
      </nav>
    </>
  );
}

export default Navbar;
