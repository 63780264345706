import React from "react";
import { NavLink } from "react-router-dom";
import "./styles.scss";
export default function Button({ title, width }) {
  return (
    <div className="button" style={{ width: width }} >
      {/* <NavLink
        exact
        to="/"
        // onClick={handleClick}
        
      > */}
       <a href="https://thee-help-web-app.vercel.app/">

        {title}
       </a>
      {/* </NavLink> */}
    </div>
  );
}
