import React from "react";
import { Container } from "react-bootstrap";
import MainBanner from "../components/MainBanner/MainBanner";
import Navbar from "../components/Navbar/Navbar";
import { useLocation } from "react-router-dom";
import "./styles/PricePlans.scss";
import FAQ from "../components/FAQ/FAQ";
import Footer from "../components/Footer/Footer";
import Form from "react-bootstrap/Form";
import AdvanceFeature from "../components/AdvanceFeature/AdvanceFeature";
const PricePlans = () => {
  const location = useLocation();
  const checkboxArray = [
    1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
  ];
  return (
    <div>
      <Navbar />
      <MainBanner title="Pricing Plan" fontSize={44} />
      <Container>
        <p className="simple-text mt-4">
          Home<span className="theme-golden-color ">{location.pathname}</span>
        </p>
        <div className="price-plan-main-container">
          <p className="theme-golden-color component-title-color price-plan-title">Pricing Plans</p>
          {/* <p className="theme-golden-color component-title-color price-plan-subtitle">
            Best prices 
          </p> */}
          <p className="price-plan-desc">
            Choose From Our Price Plans
          </p>
          <div id="ss">
            <div className="price-plan-list-container">
              <div className="price-plans-price-container">
                <div>
                  <div className="price-plans-title-container">
                    <p
                      className="price-plans-desc mt-4"
                      style={{ color: "white" }}
                    >
                      All prices are in USD
                    </p>
                    <span style={{ display: "flex" }}>
                      <Form.Check
                        disabled
                        type="radio"
                        label="Per Annually"
                        className="component-title-color price-plans-title price-plans-input"
                        defaultChecked
                        // id={`disabled-default-${type}`}
                      />
                      &nbsp; &nbsp; &nbsp;
                      <Form.Check
                        disabled
                        type="radio"
                        label="Pay Monthly"
                        className="component-title-color price-plans-title"
                      />
                    </span>
                  </div>
                  <div
                    className="price-plans-listing-container"
                    style={{
                      marginTop: "120px",
                    }}
                  >
                    <p className="theme-golden-color">Emerging</p>
                    <ul>
                      <li>Number of staff/locations</li>
                      <li>Offer unlimited services and appointments</li>
                      <li>
                        Live Accept payments and deposits through Stripe,
                        Square, or PayPal
                      </li>
                      <li>
                        Google Meet, Zoom, and GoToMeeting integrations for
                        online meetings
                      </li>
                      <li>Advanced Features (see below)</li>
                      <li>Confirmation, reminder, and follow-up emails</li>
                      <li>Text message reminders (worldwide)</li>
                      <li>
                        Offer appointment packages (including payment plans)
                      </li>
                      <li>Automatic time zone conversion for clients</li>
                      <li>
                        Multiple time zones for your different staff/locations
                      </li>
                    </ul>
                  </div>
                  <div className="price-plans-listing-container">
                    <p className="theme-golden-color">Emerging</p>
                    <ul>
                      <li>Number of staff/locations</li>
                      <li>Offer unlimited services and appointments</li>
                      <li>
                        Live Accept payments and deposits through Stripe,
                        Square, or PayPal
                      </li>
                      <li>
                        Google Meet, Zoom, and GoToMeeting integrations for
                        online meetings
                      </li>
                      <li>Advanced Features (see below)</li>
                      <li>Confirmation, reminder, and follow-up emails</li>
                      <li>Text message reminders (worldwide)</li>
                      <li>
                        Offer appointment packages (including payment plans)
                      </li>
                      <li>Automatic time zone conversion for clients</li>
                      <li>
                        Multiple time zones for your different staff/locations
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="price-plans-checkbox-container">
                  <p className="theme-golden-color">
                    <span
                      className="dollar-sign"
                      style={{ textAlign: "revert" }}
                    >
                      $
                    </span>
                    <span
                      style={{ color: "yellow" }}
                      className="price-plans-value"
                    >
                      7
                    </span>
                    <span
                      style={{ color: "yellow" }}
                      className="price-plans-card-desc1"
                    >
                      /per month
                    </span>
                  </p>
                  <p className="price-plans-card-desc"> Save 6% Annually</p>
                  <button className="price-plans-card-button">
                    START FREE 7-DAY TRIAL
                  </button>
                  <div
                    style={{
                      marginTop: "30px",
                    }}
                  ></div>
                  {checkboxArray.map((item) => (
                    <span
                      className="price-plans-card-checkbox"
                      // style={{
                      //   paddingTop: "0px",
                      //   // marginTop: "50px",
                      // }}
                    >
                      {" "}
                      <Form.Check
                        disabled
                        type="checkbox"
                        label=""
                        value={item}
                        className="component-title-color price-plans-title price-plans-input"
                        defaultChecked
                        // id={`disabled-default-${type}`}
                      />
                    </span>
                  ))}
                </div>
                <div className="price-plans-checkbox-container">
                  <p className="theme-golden-color">
                    <span
                      className="dollar-sign"
                      style={{ textAlign: "revert" }}
                    >
                      $
                    </span>
                    <span className="price-plans-value">20</span>
                    <span
                      style={{ color: "yellow" }}
                      className="price-plans-card-desc1"
                    >
                      /per month
                    </span>
                  </p>
                  <p className="price-plans-card-desc"> Save 6% Annually</p>
                  <button className="price-plans-card-button">
                    START FREE 7-DAY TRIAL
                  </button>
                  <div
                    style={{
                      marginTop: "30px",
                    }}
                  ></div>
                  {checkboxArray.map((item) => (
                    <span
                      className="price-plans-card-checkbox"
                      //   style={{
                      //     paddingTop: "5px",
                      //     marginTop: "50px",
                      //   }}
                    >
                      {" "}
                      <Form.Check
                        disabled
                        type="checkbox"
                        label=""
                        value={item}
                        className="component-title-color price-plans-title price-plans-input"
                        defaultChecked
                        // id={`disabled-default-${type}`}
                      />
                    </span>
                  ))}
                </div>
                <div className="price-plans-checkbox-container">
                  <p className="theme-golden-color">
                    <span
                      className="dollar-sign"
                      style={{ textAlign: "revert" }}
                    >
                      $
                    </span>
                    <span className="price-plans-value">40</span>
                    <span
                      style={{ color: "yellow" }}
                      className="price-plans-card-desc1"
                    >
                      /per month
                    </span>
                  </p>
                  <p className="price-plans-card-desc"> Save 6% Annually</p>
                  <button className="price-plans-card-button">
                    START FREE 7-DAY TRIAL
                  </button>
                  <div
                    style={{
                      marginTop: "30px",
                    }}
                  ></div>
                  {checkboxArray.map((item) => (
                    <span
                      className="price-plans-card-checkbox"
                      //   style={{
                      //     paddingTop: "5px",
                      //     marginTop: "50px",
                      //   }}
                    >
                      {" "}
                      <Form.Check
                        disabled
                        type="checkbox"
                        label=""
                        value={item}
                        className="component-title-color price-plans-title price-plans-input"
                        defaultChecked
                        // id={`disabled-default-${type}`}
                      />
                    </span>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* //first card */}
        <div id="dd">
          <div className="plc">
            <div className="price-plan-list-container">
              <div className="price-plans-price-container">
                <div>
                  <div className="price-plans-title-container">
                    <p
                      className="price-plans-desc mt-4"
                      style={{ color: "white" }}
                    >
                      All prices are in USD
                    </p>
                    <span style={{ display: "flex" }}>
                      <Form.Check
                        disabled
                        type="radio"
                        label="Per Annually"
                        className="component-title-color price-plans-title price-plans-input"
                        defaultChecked
                        // id={`disabled-default-${type}`}
                      />
                      &nbsp; &nbsp; &nbsp;
                      <Form.Check
                        disabled
                        type="radio"
                        label="Pay Monthly"
                        className="component-title-color price-plans-title"
                      />
                    </span>
                  </div>
                  <div
                    className="price-plans-listing-container"
                    style={{
                      marginTop: "120px",
                    }}
                  >
                    <p className="theme-golden-color">Assistant</p>
                    <ul>
                      <li>Number of staff/locations</li>
                      <li>Offer unlimited services and appointments</li>
                      <li>
                        Live Accept payments and deposits through Stripe,
                        Square, or PayPal
                      </li>
                      <li>
                        Google Meet, Zoom, and GoToMeeting integrations for
                        online meetings
                      </li>
                      <li>Advanced Features (see below)</li>
                      <li>Confirmation, reminder, and follow-up emails</li>
                      <li>Text message reminders (worldwide)</li>
                      <li>
                        Offer appointment packages (including payment plans)
                      </li>
                      <li>Automatic time zone conversion for clients</li>
                      <li>
                        Multiple time zones for your different staff/locations
                      </li>
                    </ul>
                  </div>
                  <div className="price-plans-listing-container">
                    <p className="theme-golden-color">Assistant</p>
                    <ul>
                      <li>Number of staff/locations</li>
                      <li>Offer unlimited services and appointments</li>
                      <li>
                        Live Accept payments and deposits through Stripe,
                        Square, or PayPal
                      </li>
                      <li>
                        Google Meet, Zoom, and GoToMeeting integrations for
                        online meetings
                      </li>
                      <li>Advanced Features (see below)</li>
                      <li>Confirmation, reminder, and follow-up emails</li>
                      <li>Text message reminders (worldwide)</li>
                      <li>
                        Offer appointment packages (including payment plans)
                      </li>
                      <li>Automatic time zone conversion for clients</li>
                      <li>
                        Multiple time zones for your different staff/locations
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="price-plans-checkbox-container">
                  <p className="theme-golden-color">
                    <span
                      className="dollar-sign"
                      style={{ textAlign: "revert" }}
                    >
                      $
                    </span>
                    <span className="price-plans-value">7</span>
                    <span
                      style={{ color: "yellow" }}
                      className="price-plans-card-desc1"
                    >
                      /per month
                    </span>
                  </p>
                  <p className="price-plans-card-desc"> Save 6% Annually</p>
                  <button className="price-plans-card-button">
                    START FREE 7-DAY TRIAL
                  </button>
                  <div
                    style={{
                      marginTop: "30px",
                    }}
                  ></div>
                  {checkboxArray.map((item) => (
                    <span
                      className="price-plans-card-checkbox"
                      style={{
                        paddingTop: "1px",
                        marginTop: "29px",
                      }}
                    >
                      {" "}
                      <Form.Check
                        disabled
                        type="checkbox"
                        label=""
                        value={item}
                        className="component-title-color price-plans-title price-plans-input"
                        defaultChecked
                        // id={`disabled-default-${type}`}
                      />
                    </span>
                  ))}
                </div>
              </div>
            </div>
            {/* secound card */}
            <div className="price-plan-list-container">
              <div className="price-plans-price-container">
                <div>
                  <div className="price-plans-title-container">
                    <p
                      className="price-plans-desc mt-4"
                      style={{ color: "white" }}
                    >
                      All prices are in USD
                    </p>
                    <span style={{ display: "flex" }}>
                      <Form.Check
                        disabled
                        type="radio"
                        label="Per Annually"
                        className="component-title-color price-plans-title price-plans-input"
                        defaultChecked
                        // id={`disabled-default-${type}`}
                      />
                      &nbsp; &nbsp; &nbsp;
                      <Form.Check
                        disabled
                        type="radio"
                        label="Pay Monthly"
                        className="component-title-color price-plans-title"
                      />
                    </span>
                  </div>
                  <div
                    className="price-plans-listing-container"
                    style={{
                      marginTop: "120px",
                    }}
                  >
                    <p className="theme-golden-color">Director</p>
                    <ul>
                      <li>Number of staff/locations</li>
                      <li>Offer unlimited services and appointments</li>
                      <li>
                        Live Accept payments and deposits through Stripe,
                        Square, or PayPal
                      </li>
                      <li>
                        Google Meet, Zoom, and GoToMeeting integrations for
                        online meetings
                      </li>
                      <li>Advanced Features (see below)</li>
                      <li>Confirmation, reminder, and follow-up emails</li>
                      <li>Text message reminders (worldwide)</li>
                      <li>
                        Offer appointment packages (including payment plans)
                      </li>
                      <li>Automatic time zone conversion for clients</li>
                      <li>
                        Multiple time zones for your different staff/locations
                      </li>
                    </ul>
                  </div>
                  <div className="price-plans-listing-container">
                    <p className="theme-golden-color">Director</p>
                    <ul>
                      <li>Number of staff/locations</li>
                      <li>Offer unlimited services and appointments</li>
                      <li>
                        Live Accept payments and deposits through Stripe,
                        Square, or PayPal
                      </li>
                      <li>
                        Google Meet, Zoom, and GoToMeeting integrations for
                        online meetings
                      </li>
                      <li>Advanced Features (see below)</li>
                      <li>Confirmation, reminder, and follow-up emails</li>
                      <li>Text message reminders (worldwide)</li>
                      <li>
                        Offer appointment packages (including payment plans)
                      </li>
                      <li>Automatic time zone conversion for clients</li>
                      <li>
                        Multiple time zones for your different staff/locations
                      </li>
                    </ul>
                  </div>
                </div>

                <div className="price-plans-checkbox-container">
                  <p className="theme-golden-color">
                    <span
                      className="dollar-sign"
                      style={{ textAlign: "revert" }}
                    >
                      $
                    </span>
                    <span className="price-plans-value">20</span>
                    <span
                      style={{ color: "yellow" }}
                      className="price-plans-card-desc1"
                    >
                      /per month
                    </span>
                  </p>
                  <p className="price-plans-card-desc"> Save 6% Annually</p>
                  <button className="price-plans-card-button">
                    START FREE 7-DAY TRIAL
                  </button>
                  <div
                    style={{
                      marginTop: "25px",
                    }}
                  ></div>

                  {checkboxArray.map((item) => (
                    <span
                      className="price-plans-card-checkbox"
                      style={{
                        paddingTop: "1px",
                        marginTop: "29px",
                      }}
                    >
                      {" "}
                      <Form.Check
                        disabled
                        type="checkbox"
                        label=""
                        value={item}
                        className="component-title-color price-plans-title price-plans-input"
                        defaultChecked
                        // id={`disabled-default-${type}`}
                      />
                    </span>
                  ))}
                </div>
              </div>
            </div>

            {/* third card */}
            <div className="price-plan-list-container">
              <div className="price-plans-price-container">
                <div>
                  <div className="price-plans-title-container">
                    <p
                      className="price-plans-desc mt-4"
                      style={{ color: "white" }}
                    >
                      All prices are in USD
                    </p>
                    <span style={{ display: "flex" }}>
                      <Form.Check
                        disabled
                        type="radio"
                        label="Per Annually"
                        className="component-title-color price-plans-title price-plans-input"
                        defaultChecked
                        // id={`disabled-default-${type}`}
                      />
                      &nbsp; &nbsp; &nbsp;
                      <Form.Check
                        disabled
                        type="radio"
                        label="Pay Monthly"
                        className="component-title-color price-plans-title"
                      />
                    </span>
                  </div>
                  <div
                    className="price-plans-listing-container"
                    style={{
                      marginTop: "120px",
                    }}
                  >
                    <p className="theme-golden-color">CEO</p>
                    <ul>
                      <li>Number of staff/locations</li>
                      <li>Offer unlimited services and appointments</li>
                      <li>
                        Live Accept payments and deposits through Stripe,
                        Square, or PayPal
                      </li>
                      <li>
                        Google Meet, Zoom, and GoToMeeting integrations for
                        online meetings
                      </li>
                      <li>Advanced Features (see below)</li>
                      <li>Confirmation, reminder, and follow-up emails</li>
                      <li>Text message reminders (worldwide)</li>
                      <li>
                        Offer appointment packages (including payment plans)
                      </li>
                      <li>Automatic time zone conversion for clients</li>
                      <li>
                        Multiple time zones for your different staff/locations
                      </li>
                    </ul>
                  </div>
                  <div className="price-plans-listing-container">
                    <p className="theme-golden-color">CEO</p>
                    <ul>
                      <li>Number of staff/locations</li>
                      <li>Offer unlimited services and appointments</li>
                      <li>
                        Live Accept payments and deposits through Stripe,
                        Square, or PayPal
                      </li>
                      <li>
                        Google Meet, Zoom, and GoToMeeting integrations for
                        online meetings
                      </li>
                      <li>Advanced Features (see below)</li>
                      <li>Confirmation, reminder, and follow-up emails</li>
                      <li>Text message reminders (worldwide)</li>
                      <li>
                        Offer appointment packages (including payment plans)
                      </li>
                      <li>Automatic time zone conversion for clients</li>
                      <li>
                        Multiple time zones for your different staff/locations
                      </li>
                    </ul>
                  </div>
                </div>

                <div className="price-plans-checkbox-container">
                  <p className="theme-golden-color">
                    <span
                      className="dollar-sign"
                      style={{ textAlign: "revert" }}
                    >
                      $
                    </span>
                    <span className="price-plans-value">40</span>
                    <span
                      style={{ color: "yellow" }}
                      className="price-plans-card-desc1"
                    >
                      /per month
                    </span>
                  </p>
                  <p className="price-plans-card-desc"> Save 6% Annually</p>
                  <button className="price-plans-card-button">
                    START FREE 7-DAY TRIAL
                  </button>
                  <div
                    style={{
                      marginTop: "30px",
                    }}
                  ></div>
                  {checkboxArray.map((item) => (
                    <span
                      className="price-plans-card-checkbox"
                      style={{
                        paddingTop: "1px",
                        marginTop: "29px",
                      }}
                    >
                      {" "}
                      <Form.Check
                        disabled
                        type="checkbox"
                        label=""
                        value={item}
                        className="component-title-color price-plans-title price-plans-input"
                        defaultChecked
                        // id={`disabled-default-${type}`}
                      />
                    </span>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* </div> */}
        <AdvanceFeature />
        <FAQ />
      </Container>
      <Footer />
    </div>
  );
};

export default PricePlans;
