import React from "react";
import "./styles.scss";
import homeHeader from "../../assets/images/home-header.png";
import Button from "../Button/Button";
export default function HomeHeader() {
  return (
    <section
      id="hero"
      className="d-flex flex-row justify-content-center align-items-center"
    >
      <div
        // class="container position-relative"
        className="d-flex flex-row"
        data-aos="zoom-in"
        data-aos-delay="100"
      >
        <div className="d-flex flex-column justify-content-center ">
          <h1>
            Book And <br />
            Manage Appointment <br />
            At One Stop
          </h1>
          <h2>
            Swift and Smooth Experience<br/> Focusing Your Needs 
            
          </h2>
          <div className="header-button">
            <a href="https://thee-help-web-app.vercel.app/"><Button title={"Book Now"} width="170px" /></a>
            
          </div>
        </div>
        <img src={homeHeader} alt="Header" height={550}/>
      </div>
    </section>
  );
}
