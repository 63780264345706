import React from 'react'
import "./styles.scss"
const AdvanceFeature = () => {
    return (
        <div className='AdvanceFeature-main-container'>
            <p className='Component-title-color advance-features-p' style={{ fontSize: "22px", textAlign: "center" }}>Advanced Features (All Paid Plans)</p>
            <br></br>
            <div className='AdvanceFeature-list-container'>
                <div>
                    <ul className='AdvanceFeature-ul'>
                        <li>Install a scheduler on your website.</li>
                        <li>Store credit cards so you can subsequently charge
                            customers</li>
                        <li>You may allow clients to tip you.</li>
                        <li>Offer savings through coupons and vouchers.</li>
                        <li>Client upselling is simple with checkout add-ons.</li>
                        <li>Advanced reporting on performances, no-shows, and
                            appointments</li>
                    </ul>
                </div>
                <div>
                    <ul className='AdvanceFeature-ul'>
                        <li>Install a scheduler on your website.</li>
                        <li>Store credit cards so you can subsequently charge
                            customers</li>
                        <li>You may allow clients to tip you.</li>
                        <li>Offer savings through coupons and vouchers.</li>
                        <li>Client upselling is simple with checkout add-ons.</li>
                        <li>Advanced reporting on performances, no-shows, and
                            appointments</li>
                    </ul>
                </div>
            </div>
        </div>
    )
}

export default AdvanceFeature