import React from "react";
import Button from "../Button/Button";
import secondSectionImage from "../../assets/images/second-section.png";
import "./styles.scss";
export default function SecondSection() {
  return (
    <section
      id="second-section"
      className="d-flex flex-row justify-content-center align-items-center"
    >
      <div
        // class="container position-relative"
        className="d-flex flex-row"
        data-aos="zoom-in"
        data-aos-delay="100"
      >
        <div className="d-flex flex-column justify-content-center ">
         
          <h1>Wide Range of  <br/>
             Time Slots<br/>
             Choose What<br/>
              Works For You?"</h1>
              <br></br>
          <h2>
            Considering your busy routine we offer multiple time slots. 
             <br /> Cancel Or Reschedule Anytime. 
          
          </h2>
        </div>
        <img className='second-section-image' src={secondSectionImage} alt="Header" />
      </div>
    </section>
  );
}
