import React from 'react'
import "./styles.scss"
const MainBanner = ({title, fontSize}) => {
    return (
        <div className='mainbanner-main-container'>
            <p className='Component-title-color font-help-banner' style={{fontSize:`${fontSize}px`}}>{title}</p>
        </div>
    )
}

export default MainBanner