import React from "react";
import Button from "../Button/Button";
import "./styles.scss"
import fourthSectionImage from "../../assets/images/fourth-section.png"
export default function FourthSection() {
  return (
    <section
      id="fourth-section"
      className="d-flex flex-row justify-content-center align-items-center"
    >
      <div
        // class="container position-relative"
        className="d-flex flex-row"
        data-aos="zoom-in"
        data-aos-delay="100"
      >
        <div className="d-flex flex-column justify-content-center ">
          <h1>
          See how our <br/> dedicated team can <br/> help you
          </h1>
          <br></br>
          <h2>
            We strive to provide the best possible experience and service.<br/>Our team is fully equipped to provide you highest quality service.
          </h2>
          <div className="header-button">
            <a href="https://thee-help-web-app.vercel.app/"><Button  title={"Book Now"}  /></a>
            
          </div>
        </div>
        <img className="fourth-section-image" src={fourthSectionImage} alt="Header" />
      </div>
    </section>
  );
}
