import React from "react";
import { Box, Grid, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";

import MuiLink from "@mui/material/Link";
// import logo from "../../Assets/images/footer_logo.png";
import logo from "../../assets/images/navbar-logo.png";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import sendIcon from "../../assets/images/send-icon.png";
import sms from "../../assets/images/sms.png";
import "./styles.scss";
import { Image } from "react-bootstrap";
import InputAdornment from "@mui/material/InputAdornment";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import AccountCircle from "@mui/icons-material/AccountCircle";
import googlePlayImage from "../../assets/images/footeer-section-images/google play.png";
import appleAppStoreImage from "../../assets/images/footeer-section-images/app store.png";


export default function Footer({ style }) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isTablet = useMediaQuery(theme.breakpoints.down("md"));
  const isLaptop = useMediaQuery(theme.breakpoints.down("lg"));

  const ColorStyle = {
    color: "#1480F3",
  };

  const FontMenuStyle = {
    fontSize: isMobile ? "12px" : "15px",
    color: "#ffffff",
  };
  const FontHeadingStyle = {
    fontSize: isMobile ? "15px" : "18px",
    // color: "#1480F3",
  };
  const socialIconStyle = {
    fontSize: isMobile ? "18px" : "1.5rem",
    // color: "#1480F3",
  };
  const socialIconMargin = {
    marginLeft: "3px",
  };
  return (
    <Box className="main-footer-container" pt={4}>
      <Box className="footer-section-container">
        <Grid container spacing={3} pt={4} pb={4}>
          <Grid
            item
            xs={12}
            sm={12}
            md={3}
            lg={3}
            xl={3}
            pr={isMobile || isLaptop || isTablet ? 0 : 10}
            className="footer-textField-grid"
          >
            <MuiLink color="inherit" href={"/"} className="parent-link">
              <Image
                src={logo}
                alt=""
                fluid
                className=" img-responsive"
                loading="lazy"
              />
            </MuiLink>
            <TextField
              id="input-with-icon-textfield"
              // label="TextField"
              sx={{
                color: "wheat",
              }}
              placeholder="Your email"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="start">
                    <img src={sendIcon} />
                  </InputAdornment>
                ),
                startAdornment: (
                  <InputAdornment position="start">
                    <img src={sms} />
                  </InputAdornment>
                ),
              }}
              variant="outlined"
            />
            {/* <Typography
              className="small-font  mt-4 silicon-nexus-footer-message"
              sx={{ fontSize: isMobile ? "12px !important" : "15px", color:"#fff" }}
            >
              We excel in visualization of ground breaking ideas and
              transforming them to reality. With the challenging analytical
              skills, we empower the technical resources and help our clients.
            </Typography> */}
          </Grid>

          <Grid
            item
            xs={7}
            sm={7}
            md={3}
            lg={2}
            xl={2}
            pl={isMobile || isTablet || isLaptop ? 0 : "4rem !important"}
          >
            <h6 className=" mb-3 footer-text-heading" style={FontHeadingStyle}>
              Quick Links
            </h6>
            {/* <hr className="hr-line-footer-heading" style={ColorStyle} /> */}
           
            <p style={FontMenuStyle}>
              <Link to="/"  className=" underline-text">
                Home
              </Link>
            </p>
            <p style={FontMenuStyle}>
              <Link
                to="/price-plan"
                className=" underline-text"
              >
                Pricing
              </Link>
            </p>
            <p style={FontMenuStyle}>
              <Link to="/help-center" className=" underline-text">
                Help 
              </Link>
            </p>
            <p style={FontMenuStyle}>
              <Link 
              onClick={()=>{
                window.location.replace('https://thee-help-web-app.vercel.app/')
              }}
               
                className=" underline-text"
              >
                Login
              </Link>
            </p>
           
           
            
           
          </Grid>

          <Grid item xs={5} sm={5} md={3} lg={2} xl={2}>
            <h6 className=" mb-3  footer-text-heading" style={FontHeadingStyle}>
              Company
            </h6>
            {/* <hr className="hr-line-footer-heading" style={ColorStyle} /> */}
            

            <p style={FontMenuStyle}>
              <Link to="/term-of-service" className=" underline-text">
                Terms of Service
              </Link>
            </p>
          
          </Grid>
          <Grid item xs={5} sm={5} md={3} lg={2} xl={2}>
            <h6 className=" mb-3  footer-text-heading" style={FontHeadingStyle}>
              Support
            </h6>
            {/* <hr className="hr-line-footer-heading" style={ColorStyle} /> */}
            <p style={FontMenuStyle}>
              <Link to="/help-center" className=" underline-text">
                FAQ
              </Link>
            </p>
          
          
         
          </Grid>
          <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
            <h6 className=" mb-3  footer-text-heading" style={FontHeadingStyle}>
              CONTACT
            </h6>
            {/* <p className=" " style={FontMenuStyle}>
              <i className="fas fa-home me-3" style={ColorStyle}></i>
              Office- FF47, New liberty plaza, Model town link road, Lahore.
            </p>
            <p className="" style={FontMenuStyle}>
              <i className="fas fa-envelope me-3" style={ColorStyle}></i>{" "}
              sales@silicon-nexus.com
            </p>
            <p className="" style={FontMenuStyle}>
              <i className="fas fa-phone me-3" style={ColorStyle}></i> + 92 303
              XXXXXXX
            </p> */}

            <ul
              className="list-unstyled d-flex  social-circle-icons"
              style={{ marginLeft: "-6px" }}
            >
              <li className="px-2 text-center social-icon">
                <a href="">
                  <i
                    className="fa fa-linkedin social-icon"
                    style={socialIconStyle}
                  ></i>
                </a>
              </li>

              <li
                className="px-2 text-center social-icon"
                style={socialIconMargin}
              >
                <a href="">
                  <i
                    className="fa fa-twitter social-icon"
                    style={socialIconStyle}
                  ></i>
                </a>
              </li>
              <li
                className="px-2 text-center social-icon"
                style={socialIconMargin}
              >
                <a href="">
                  <i
                    className="fa fa-instagram social-icon"
                    style={socialIconStyle}
                  ></i>
                </a>
              </li>
              <li
                className="px-2 align-items-center text-center social-icon"
                style={socialIconMargin}
              >
                <a href="">
                  <i
                    className="fa fa-facebook social-icon"
                    style={socialIconStyle}
                  ></i>
                </a>
              </li>
            </ul>
            <br></br>
            {/* Code for the payment Method  */}
            {/* <h6 className=" mb-3  footer-text-heading" style={FontHeadingStyle}>
              Payments
            </h6>
            <img className='payment-square-image' src={squareLogo} alt="squareLogo"/> */}
            <h6 className=" mb-3  footer-text-heading" style={FontHeadingStyle}>
              Download
            </h6>
            <a href="">
              <img
                className="google-play-image "
                src={googlePlayImage}
                alt="googlePlayImage"
              />
            </a>

            <a href="">
            <img
              className="apple-app-store-image ml-4"
              src={appleAppStoreImage}
              alt="appleAppStoreImage"
            />
            </a>
           

           
          </Grid>
        </Grid>
        <Box>
          <div
            className="text-center  p-3"
            // style={{ backgroundColor: "rgba(0, 0, 0, 0.2)" }}
            // style={{ backgroundColor: "#F8F9FB" }}
          >
            <hr
              style={{
                color: "rgba(180, 180, 180, 0.7)",
                width: "100%",
                border: "1px solid #FFD66B",
              }}
            ></hr>
            <p className="theme-golden-color">
              © 2022 Scheduling. Copyright and All rights reserved.
            </p>
            Powered By :
            <Link className="" to="https://silicon-nexus.com/">
              SiliconNexus
            </Link>
          </div>
        </Box>
      </Box>
    </Box>
  );
}
