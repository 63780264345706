import React from 'react'
import "./styles.scss"
import Slider from "react-slick";
// import "~slick-carousel/slick/slick.css"; 
// import "~slick-carousel/slick/slick-theme.css";
import ClientReviewImageA from "../../assets/images/client-review-images/a.JPG"
import ClientReviewImageB from "../../assets/images/client-review-images/b.JPG"
import ClientReviewImageC from "../../assets/images/client-review-images/c.JPG"
import ClientReviewImageD from "../../assets/images/client-review-images/d.JPG"
import ClientReviewImageE from "../../assets/images/client-review-images/e.JPG"
import ClientReviewImageF from "../../assets/images/client-review-images/f.JPG"

import RightArrow from "../../assets/images/right-arrow.png"
import leftArrow from "../../assets/images/left-arrow.png"
const sildeArray = [
    {
        url: ClientReviewImageA
    },
    {
        url: ClientReviewImageB
    },
    {
        url: ClientReviewImageC
    },
    {
        url: ClientReviewImageD
    },
    {
        url: ClientReviewImageE
    },
    {
        url: ClientReviewImageF
    },
]
function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
        <img src={RightArrow} className={className} style={{ ...style, display: "block", width:"35px", height:"35px" }} onClick={onClick}  />
    );
}
function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
        <img src={leftArrow} className={className} style={{ ...style, display: "block", width:"35px", height:"35px" }} onClick={onClick}  />
    );
  }
const ClientReview = () => {
    var settings = {
        // className: "center",
        // centerMode: true,
        // centerPadding: "10px",
        // fade: true,
        slidesToShow: 3,
        slidesToScroll: 1,
        // initialSlide: 0,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />

        // responsive: [
        //     {
        //         breakpoint: 1024,
        //         settings: {
        //             slidesToShow: 3,
        //             slidesToScroll: 3,
        //             infinite: true,
        //             dots: true
        //         }
        //     },
        //     {
        //         breakpoint: 600,
        //         settings: {
        //             slidesToShow: 2,
        //             slidesToScroll: 2,
        //             initialSlide: 2
        //         }
        //     },
        //     {
        //         breakpoint: 480,
        //         settings: {
        //             slidesToShow: 1,
        //             slidesToScroll: 1
        //         }
        //     }
        // ]
    };
    return (
        <div className='ClientReview-min-container'>
            <div className='PricePlans-title-container mb-5'>
                <h1 className='Component-title-color PricePlans-title font-client-review'>Customer Reviews</h1>
            </div>
            <Slider {...settings} style={{ width: "1100px" }} >
                {sildeArray.map((item) => (
                    <div className='ClientReview-img-container'>
                        <img src={item.url} style={{ width: "300px", height: "246px", objectFit:'cover'}} />
                    </div>
                ))}

                {/* <div>
                    <h3>2</h3>
                </div>
                <div>
                    <h3>3</h3>
                </div>
                <div>
                    <h3>4</h3>
                </div> */}
                {/* <div>
                    <h3>5</h3>
                </div>
                <div>
                    <h3>6</h3>
                </div>
                <div>
                    <h3>7</h3>
                </div>
                <div>
                    <h3>8</h3>
                </div> */}
            </Slider>
        </div>
    )
}

export default ClientReview