import React from 'react'
import { Container } from 'react-bootstrap';
import Card from 'react-bootstrap/Card';
import "./styles.scss"
import card001 from "../../assets/images/power-meet/card001.png"
import card002 from "../../assets/images/power-meet/card002.png"
import card003 from "../../assets/images/power-meet/card003.png"
const CardArray = [
    {
        url: card001,
        title: "Hair Styling",
        desc: "From bouncy blowdry to festival braiding, we can solve your daily hair needs."
    },
    {
        url: card002,
        title: "Makeup",
        desc: "Flawless makeup to make you look and feel your best. We bring everything you need!"
    },
    {
        url: card003,
        title: "Waxing",
        desc: "Pain free wax for quick hair removal based on your skin type. Choose from Sugar or Fruit."
    }
]
const PowerMeet = () => {
    return (
        <Container className='PowerMeet-main-container'>
            <div className='PowerMeet-title-container'>
                <h1 className='Component-title-color PowerMeet-title'>Services We Offer</h1>
                {/* <p className='ToolScheduling-desc'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vulputate libero et velit interdum, ac aliquet odio mattis.</p> */}
            </div>
            <div className='PowerMeet-card-container'>
                {CardArray.map((card) => (
                    <Card style={{ width: '18rem' }} className="PowerMeet-card">
                        <Card.Img variant="top" src={card.url} />
                        <Card.Body>
                            <Card.Title className='theme-golden-color PowerMeet-card-title'>{card.title}</Card.Title>
                            <Card.Text className='PowerMeet-card-desc'>
                                {card.desc}
                            </Card.Text>
                        </Card.Body>
                    </Card>
                ))}
            </div>
        </Container>
    )
}

export default PowerMeet