import React from "react";
import Button from "../Button/Button";
import thirdSectionImage from "../../assets/images/third-section.png";
import tickImage from "../../assets/images/tick.png";
import "./styles.scss";
export default function ThirdSection() {
  return (
    <section
      id="third-section"
      
    >
      <div
        // class="container position-relative"
        className="abc"
        data-aos="zoom-in"
        data-aos-delay="100"
      >
        <img  className="third-section-image" src={thirdSectionImage} alt="Header" />
        <div className="">
          <h1>
           Dedicated Support Team 
          </h1>
          <br></br>
          <h2>
          Our 24/7 customer support is always ready to help you our. We are just a call away from you.
          </h2>
          <div className="d-flex flex-row align-items-center">
            <img className="tick-image" src={tickImage} alt="" />
            <h3>Visit Help Center</h3>
          </div>
          <div className="d-flex flex-row align-items-center">
            <img className="tick-image" src={tickImage} alt="" />
            <h3>Call Support</h3>
          </div>
          <div className="d-flex flex-row align-items-center">
            <img className="tick-image" src={tickImage} alt="" />
            <h3>Chat</h3>
          </div>
          <div className="d-flex flex-row align-items-center">
            <img className="tick-image" src={tickImage} alt="" />
            <h3>Feedback</h3>
          </div>
        </div>
      </div>
    </section>
  );
}
