import React from 'react'
import { Container } from 'react-bootstrap'
import "./styles.scss"
import Form from 'react-bootstrap/Form';
import PriceCards from './PriceCards';
const PricePlans = () => {
    return (
        <Container style={{
            marginBottom:"50px"
        }}>
            <div className='PricePlans-title-container'>
                <h1  className='Component-title-color PricePlans-title font-h-one-price-plan-mobile'>Choose Your Service</h1>
                <br></br>
                <h5 className='Component-title-color PricePlans-title'>Price Plans </h5>
                <p className='PricePlans-desc'>Prices according yo your needs. </p>
            </div>
            <div className='PricePlans-title-container'>
                <p className='PricePlans-desc mt-4'>All prices are in USD</p>
                <span style={{ display: "flex" }}>
                    <Form.Check
                        disabled
                        type="radio"
                        label="Per Annually"
                        className='Component-title-color PricePlans-title PricePlans-input'
                        defaultChecked
                    // id={`disabled-default-${type}`}
                    />
                   
                   
                    <Form.Check
                        disabled
                        type="radio"
                        label="Pay Monthly"
                        className='Component-title-color PricePlans-title'
                    />
                </span>
            </div>
            <div>
                <PriceCards />
            </div>
        </Container>
    )
}

export default PricePlans