import React from 'react'
import { Container } from 'react-bootstrap'
import Badge from 'react-bootstrap/Badge';
import "./styles.scss"
const toolArray = [
    {
        title: "Choose Your Time Slot",
        desc: "Select from the wide range of time slots"
    },
    {
        title: "Choose Your Service",
        desc: "Pick the service you would like to avail"
    },
    {
        title: "Book Now",
        desc: "Instant booking, Just one click away"
    },
    {
        title: "Online Payment",
        desc: "Pay through wide range of platforms"
    },
    {
        title: "Instant Confirmation",
        desc: "Receive confirmation email as soon as you book the service"
    },
    {
        title: "Sync Calender",
        desc: "Appointment syncing with your calendar on a click of button"
    },

    {
        title: "Cancel / Reschedule",
        desc: "Hassle free cancelation or rescheduling"
    },

]
const ToolScheduling = () => {
    return (
        <Container className='ToolScheduling-main-container'>
            <div className='ToolScheduling-title-container'>
                <h1 className='Component-titlee-tool-color'>Easy Booking Process </h1>
                <br></br>
                <p className='ToolScheduling-desc'>Hassle free way to avail our services with peace of mind</p>
            </div>
            <div className='ToolScheduling-tool-upper-box'>
                {toolArray.map((item, index) => (
                    <div className='ToolScheduling-tool-inner-box '>
                        <span className=' ToolScheduling-Badge rounded-circle' bg="danger">
                            <span className='theme-golden-color size-adjustment'> 0{index + 1}</span>
                        </span>
                        <p className='theme-golden-color ToolScheduling-tool-title '>{item.title}</p>
                        <p className=' theme-font-family ToolScheduling-tool-desc'>{item.desc}</p>
                    </div>
                ))}
            </div>
        </Container>
    )
}

export default ToolScheduling