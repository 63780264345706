import React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import image1 from "../../assets/images/image-29.png";
import image2 from "../../assets/images/image-30.png";
import image3 from "../../assets/images/image-31.png";
import nextIcon from "../../assets/images/arrow-next.png";
import prevIcon from "../../assets/images/arrow-prev.png";
import "./styles.scss";

const responsive = {
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 3,
        partialVisibilityGutter: 40,
    },
    tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 2,
        partialVisibilityGutter: 30,
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1,
        partialVisibilityGutter: 30,
    },
};

const items = [
    { id: 1, img: image1, title: "Staff Name", des: "Person" },
    { id: 2, img: image2, title: "Staff Name", des: "Person" },
    { id: 3, img: image3, title: "Staff Name", des: "Person" },
    { id: 4, img: image1, title: "Staff Name", des: "Person" },
    { id: 5, img: image2, title: "Staff Name", des:"Person" },
];

const ItemCard = ({ item }) => {
    return (
        <div className="industry-cards">
            <div><img src={item.img} /></div>
            <div>
                <p className="industry-cards-title">{item.title}</p>
                <p className="industry-cards-des">{item.des}</p>
            </div>
            {/* {/ Add other item details and styling as needed /} */}
        </div>
    );
};

const ButtonGroup = ({ next, previous, goToSlide, ...rest }) => {
    const { carouselState: { currentSlide } } = rest;
    return (
        <div className="carousel-button-group">
                {/* <div style={{paddingRight:'50px'}}><span className="active-items-cards">01</span><span className="total-items-cards">/05</span></div> */}
                <img src={prevIcon} onClick={() => previous()} disabled={currentSlide === 0} style={{marginRight:"20px"}} ></img>
                <img src={nextIcon} onClick={() => next()} disabled={currentSlide >= items.length - 3} ></img>
          
        </div>
    );
};

const AdjustIndustry = () => {
    return (
        <div className="items-main-container" style={{ marginTop: "50px", marginBottom:"80px" }}>
            <div style={{marginBottom:"15px"}}>
                <p className="industry-heading">Friendly and Knowledgeable Staff</p>
                <p style={{width:"inherit"}} className="industry-para">We take responsibility to deliver the best possible experience for you.</p>
            </div>
            <div className="items" style={{ marginTop: "0px " }}>
                <Carousel responsive={responsive} partialVisible={true} customButtonGroup={<ButtonGroup />}>
                    {items.map((item) => (
                        <ItemCard key={item.id} item={item} />
                    ))}
                </Carousel>
            </div>
        </div>
    );
};

export default AdjustIndustry;