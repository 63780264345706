import React from 'react'
import { Container } from 'react-bootstrap'
import MainBanner from '../components/MainBanner/MainBanner'
import Navbar from '../components/Navbar/Navbar'
import { useLocation } from 'react-router-dom'
import "./styles/HelpCenterStyles.scss"
import Footer from '../components/Footer/Footer'

import FAQ from '../components/FAQ/FAQ'
const HelpCenter = () => {
    const location = useLocation()
    return (
        <div>
            <Navbar />
            <MainBanner title="Help Center" fontSize={44} />
            <Container>
                <FAQ></FAQ>
                {/* <p className='simple-text mt-4'>Home<span className='theme-golden-color '>{location.pathname}</span></p>
                <div>
                    <p className='Component-title-color helpCenter-title'>Booking</p>
                    <div className='helpCenter-listing-main-container'>
                    <div>
                            <p className='Component-title-color helpCenter-listing-title'>Booking</p>
                            <p className='helpCenter-listing-text'>Blocking off unavailable time in Scheduling</p>
                            <p className='helpCenter-listing-text'>Getting started with Squarespace Scheduling</p>
                            <p className='helpCenter-listing-text'>Offering recurring appointments Schedulling</p>
                            <p className='helpCenter-listing-text'>Availability and scheduling limits by appointment type</p>
                            <p className='helpCenter-listing-text'>Adding labels to appointments or class attendees in Scheduling</p>
                            <p className='helpCenter-listing-text'>Tracking appointments changes in Scheduling</p>
                            <p className='helpCenter-listing-text Component-title-color' style={{ textDecoration:"underline" }}>SEE ALL 12 GUIDES</p>
                        </div>
                        <div>
                            <p className='Component-title-color helpCenter-listing-title'>Payments</p>
                            <p className='helpCenter-listing-text'>Starting a Scheduling trial</p>
                            <p className='helpCenter-listing-text'>Getting started with Squarespace Scheduling</p>
                            <p className='helpCenter-listing-text'>Squarespace Scheduling FAQ</p>
                            <p className='helpCenter-listing-text'>Switching to Scheduling</p>
                            <p className='helpCenter-listing-text'>Creating and editing appointment types</p>
                            <p className='helpCenter-listing-text'>Managing availability and calendars</p>
                            <p className='helpCenter-listing-text Component-title-color' style={{ textDecoration:"underline" }}>SEE ALL 12 GUIDES</p>
                        </div>
                        <div>
                            <p className='Component-title-color helpCenter-listing-title'>Cancellation</p>
                            <p className='helpCenter-listing-text'>Blocking off unavailable time in Scheduling</p>
                            <p className='helpCenter-listing-text'>Getting started with Squarespace Scheduling</p>
                            <p className='helpCenter-listing-text'>Offering recurring appointments Schedulling</p>
                            <p className='helpCenter-listing-text'>Availability and scheduling limits by appointment type</p>
                            <p className='helpCenter-listing-text'>Adding labels to appointments or class attendees in Scheduling</p>
                            <p className='helpCenter-listing-text'>Tracking appointments changes in Scheduling</p>
                            <p className='helpCenter-listing-text Component-title-color' style={{ textDecoration:"underline" }}>SEE ALL 12 GUIDES</p>
                        </div>
                        <div>
                            <p className='Component-title-color helpCenter-listing-title'>Customer Support</p>
                            <p className='helpCenter-listing-text'>Starting a Scheduling trial</p>
                            <p className='helpCenter-listing-text'>Getting started with Squarespace Scheduling</p>
                            <p className='helpCenter-listing-text'>Squarespace Scheduling FAQ</p>
                            <p className='helpCenter-listing-text'>Switching to Scheduling</p>
                            <p className='helpCenter-listing-text'>Creating and editing appointment types</p>
                            <p className='helpCenter-listing-text'>Managing availability and calendars</p>
                            <p className='helpCenter-listing-text Component-title-color' style={{ textDecoration:"underline" }}>SEE ALL 12 GUIDES</p>
                        </div>
                        <div>
                            <p className='Component-title-color helpCenter-listing-title'>Feedback</p>
                            <p className='helpCenter-listing-text'>Blocking off unavailable time in Scheduling</p>
                            <p className='helpCenter-listing-text'>Getting started with Squarespace Scheduling</p>
                            <p className='helpCenter-listing-text'>Offering recurring appointments Schedulling</p>
                            <p className='helpCenter-listing-text'>Availability and scheduling limits by appointment type</p>
                            <p className='helpCenter-listing-text'>Adding labels to appointments or class attendees in Scheduling</p>
                            <p className='helpCenter-listing-text'>Tracking appointments changes in Scheduling</p>
                            <p className='helpCenter-listing-text Component-title-color' style={{ textDecoration:"underline" }}>SEE ALL 12 GUIDES</p>
                        </div>
                     
                    </div>
                </div> */}
            </Container>
            <Footer/>
        </div>
    )
}

export default HelpCenter