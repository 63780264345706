import React from 'react'
import "./styles.scss"
import Card from 'react-bootstrap/Card';
import { fontWeight } from '@mui/system';
import corner from "../../assets/images/price-card-corner.png"
import { CenterFocusWeakOutlined } from '@mui/icons-material';
const PriceCards = () => {
    return (
        <div className='contain' >
            <Card style={{ width: '18rem'}} className="PricePlans-card">
                <Card.Header className='PricePlans-card-header'><span className='Component-title-color'>Assistant</span></Card.Header>
                <Card.Body>
                    {/* <Card.Title className='theme-golden-color PowerMeet-card-title'>All prices are in USD</Card.Title> */}
                    <Card.Text className='PricePlans-evaluation-container'>
                        <Card.Text className='theme-golden-color' style={{ marginBottom: "0px" }}>$ <span className='PricePlans-card-value'>7</span><span className='PricePlans-desc'>/per month</span></Card.Text>
                        <Card.Text className='PricePlans-desc'>Save 6% Annually <br></br> <hr style={{
                            color: "rgba(180, 180, 180, 0.7)",
                            width: "70px",
                            border: "2px solid rgba(180, 180, 180, 0.7"
                        }}></hr></Card.Text>
                    </Card.Text>
                    <Card.Text className='PricePlans-card-ul-container'>
                        <ul className='PricePlans-card-ul'>
                            <li>Number of staff/locations</li>
                            <li>Client self-scheduling</li>
                            <li ><del>Offer unlimited services and
                                appointments</del></li>
                            <li><del>Accept payments and
                                deposits through Stripe,
                                Square, or PayPal</del></li>
                        </ul>
                    </Card.Text>
                    <div className='PricePlans-card-button-container'>
                        <a href='https://thee-help-web-app.vercel.app/'> <button className='PricePlans-button'>
                            START FREE 7-DAY TRIAL
                        </button></a>
                       
                    </div>
                </Card.Body>
            </Card>

            <Card style={{ width: '18rem' }} className="PricePlans-card">
                <Card.Header className='PricePlans-card-header'><span className='Component-title-color'>Director</span></Card.Header>
                <img src={corner} className='PricePlans-card-corner-img' />
                <Card.Body>
                    {/* <Card.Title className='theme-golden-color PowerMeet-card-title'>All prices are in USD</Card.Title> */}
                    <Card.Text className='PricePlans-evaluation-container'>
                        <Card.Text className='theme-golden-color' style={{ marginBottom: "0px" }}>$ <span className='PricePlans-card-value'>20</span><span className='PricePlans-desc'>/per month</span></Card.Text>
                        <Card.Text className='PricePlans-desc'>Save 8% Annually <br></br> <hr style={{
                            color: "rgba(180, 180, 180, 0.7)",
                            width: "70px",
                            border: "2px solid rgba(180, 180, 180, 0.7"
                        }}></hr></Card.Text>
                    </Card.Text>
                    <Card.Text className='PricePlans-card-ul-container'>
                        <ul className='PricePlans-card-ul'>
                            <li>Number of staff/locations</li>
                            <li>Client self-scheduling</li>
                            <li>Confirmation, reminder,
                                and follow-up emails</li>
                            <li>Google Meet, Zoom, and
                                GoToMeeting integrations
                                for online meetings</li>
                        </ul>
                    </Card.Text>
                    <div className='PricePlans-card-button-container'>
                    <a href='https://thee-help-web-app.vercel.app/'>   <button className='PricePlans-button' style={{
                            background: "linear-gradient(90deg, #BA9743 0%, #FFD66B 60.12%, #BA9743 126.85%)"

                        }}>
                            START FREE 7-DAY TRIAL
                        </button></a>
                      
                    </div>
                </Card.Body>
            </Card>

            <Card style={{ width: '18rem' }} className="PricePlans-card">
                <Card.Header className='PricePlans-card-header'><span className='Component-title-color'>CEO</span></Card.Header>
                <Card.Body>
                    {/* <Card.Title className='theme-golden-color PowerMeet-card-title'>All prices are in USD</Card.Title> */}
                    <Card.Text className='PricePlans-evaluation-container'>
                        <Card.Text className='theme-golden-color' style={{ marginBottom: "0px" }}>$ <span className='PricePlans-card-value'>40</span><span className='PricePlans-desc'>/per month</span></Card.Text>
                        <Card.Text className='PricePlans-desc'>Save 10% Annually <br></br> <hr style={{
                            color: "rgba(180, 180, 180, 0.7)",
                            width: "70px",
                            border: "2px solid rgba(180, 180, 180, 0.7"
                        }}></hr></Card.Text>
                    </Card.Text>
                    <Card.Text>
                        <ul className='PricePlans-card-ul'>
                            <li>Number of staff/locations</li>
                            <li>Client self-scheduling</li>
                            <li>Offer unlimited services and
                                appointments</li>
                            <li>Accept payments and
                                deposits through Stripe,
                                Square, or PayPal</li>
                        </ul>
                    </Card.Text>
                    <div className='PricePlans-card-button-container'>
                       
                        <a href='https://thee-help-web-app.vercel.app/'>    <button className='PricePlans-button'>
                            START FREE 7-DAY TRIAL
                        </button></a>
                    </div>
                </Card.Body>
            </Card>
        </div>
    )
}

export default PriceCards